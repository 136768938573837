<template>
	<div id="storeList" class="store-list">
		<div class="el-content mb12">
			<a-space>
				<a-input-group compact>
				    <a-select v-model:value="search.status" style="width: 150px;">
				    	<a-select-option :value="1">正常</a-select-option>
				    	<a-select-option :value="0">已删除</a-select-option>
				    </a-select>
					<a-input-search v-model:value="search.key" placeholder="请输入内容" enter-button @search="getStoreList(1,info.limit)" style="width: 400px"/>
				</a-input-group>
				<router-link :to="{path:'/adhibition/store/store_add'}">
					<a-button v-has="{action:'/adhibition/store/store_add'}" type="primary">
						<i class="ri-add-line ri-lg ri-top"></i>
						添加新商户
					</a-button>
				</router-link>	
			</a-space>
			
			<div class="tips">
				<span class="ect-title">入驻商户PC端登录地址：</span>
				<span class="ect-title">{{store_login_url}}</span>
				<com-copy :value="store_login_url" btn-type="default"></com-copy>
			</div>
		</div>
		
		<div class="el-content">
			<a-table :pagination="false" row-key="shop_id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'shop_id'},
				{title:'商户LOGO',dataIndex:'logo',slots:{customRender:'logo'}},
				{title:'商户名称',dataIndex:'name',slots:{customRender:'name'}},
				{title:'用户信息',dataIndex:'user',slots:{customRender:'user'}},
				{title:'申请时间',dataIndex:'update_time'},
				{title:'店铺状态',dataIndex:'is_put_away',slots:{customRender:'is_put_away'}},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]">
				<template #logo="{record}">
					<a-image :src="record.logo" style="width: 60px;"></a-image>
				</template>
				<template #name="{record}">
					<div><span class="f12">店铺名称：</span>{{record.name}}</div>
					<div><span class="f12">登录账号：</span>{{record.user.mobile}}</div>
					<!-- <div><span class="f12">店铺地址：</span>{{record.address}}</div> -->
				</template>
				<template #user="{record}">
					<div v-if="record.account"><span class="f12">用户昵称：</span>{{record.account.nickname}}</div>
					<div><span class="f12">用户UID：</span>{{record.account_id}}</div>
					<div><span class="f12">联系人：</span>{{record.mobile}}</div>
				</template>
				<template #is_put_away="{record}">
					<a-tag :color="record.lib_status == 2 ?'#999':'#00CC66'">{{record.lib_status == 2 ?'已禁用':'启用'}}</a-tag>
				</template>
				<template #action="{record}">
					<template v-if="search.status == 1">
						<a-space>
							<router-link :to="{path:'/adhibition/store/store_detail',query:{id:record.shop_id}}">
								<kd-button type="primary" 
									title="详情" 
									icon="ri-more-fill"  
									v-has="{action:'/adhibition/store/store_detail'}">
								</kd-button>
							</router-link>
							<kd-button type="danger" title="修改密码" icon="ri-key-line" v-has="{action:'wxapp_marketing_store_update_pwd'}" @click="showPwd(record.user_id)"></kd-button>
							<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_marketing_store_del'}" @click="deleteStore(record.shop_id)"></kd-button>
						</a-space>
					</template>
					<template v-if="search.status == 0">
						<kd-button type="danger" title="恢复店铺" icon="ri-device-recover-line" v-has="{action:'wxapp_marketing_store_del'}" @click="rebackStore(record.shop_id)"></kd-button>
					</template>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getStoreList(info.page,e)}"
					@change="(e)=>{getStoreList(e,info.limit)}"
				/>
			</div>
		</div>
		<a-modal v-model:visible="pwdForm.show" title="修改密码" @ok="sureUpdatePwd" @cancel="pwdForm.show = false" width="500px">
			<a-input placeholder="新密码" v-model:value="pwdForm.password"></a-input>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import shopModel from '@/api/addons/shop.js'
import common from '@/api/common.js'
import comCopy from '@/components/public/com-copy.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'

export default{
	name:"StoreList",
	components:{
		comCopy
	},

	setup(){
		const _d = reactive({
			search:{
				status:1,
				key:'',			//手机号，店铺名称
			},
			info:{
				list:[],
				page:1,
				limit:10,
				count:0,
			},
			store_login_url:'',
			pwdForm:{
				show:false,
				shop_id:0,
				password:'',
			}
		})
		_d.store_login_url =  window.location.protocol+"//"+window.location.host+'/store/'+localStorage.getItem('program_id');
		getStoreList(1,_d.info.limit)
		function getStoreList(page,limit){
			shopModel.getShop(page,limit,_d.search,res=>_d.info = {limit,...res})
		}

		function showPwd(user_id){
			_d.pwdForm.user_id = user_id
			_d.pwdForm.show = true
		}

		const deleteStore = (id)=>common.deleteDataList(id,34,"确认要删除该商户申请吗？",()=>getStoreList(_d.info.page,_d.info.limit))
		const sureUpdatePwd =() =>shopModel.updateShopUser('password',_d.pwdForm,()=>{
			_d.pwdForm.user_id = 0
			_d.pwdForm.password = ''
			_d.pwdForm.show = false
		})
		const rebackStore = (shop_id)=>shopModel.rebackDelStore(shop_id,()=>getStoreList(_d.info.page,_d.info.limit))
		
		onBeforeRouteLeave((to,from,next)=>{
			let type = to.path == '/adhibition/store/store_detail' ? 1 : 2
			store.dispatch("setKeepPage",{name:from.name,type})
			next();
		})
		
		return{
			...toRefs(_d),
			getStoreList,
			deleteStore,
			rebackStore,
			sureUpdatePwd,
			showPwd
		}
	}


}
</script>

<style lang="scss">
.store-list{
	.tips{
		width: 100%;
		background: #f4f4f4;
		padding: 4px;
		margin-top: 12px;
		font-size: 12px;
		.ect-title{
			margin-right: 4px;
		}
	}
}
</style>
